import * as Yup from "yup";

export const walletValidation = (walletAmount: number) => {
  return Yup.object().shape({
    amount: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .required("Amount field is required")
      .when("action", {
        is: "debit",
        then: () =>
          Yup.string().test(
            "max-debit-amount",
            "Insufficient amount in wallet",
            (value) => parseFloat(value ?? "") <= walletAmount
          ),
        otherwise: () => Yup.string(),
      }),
    action: Yup.string().required("action field is required"),
  });
};
