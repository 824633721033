import { TableColumnsType, Typography } from "antd";
import moment from "moment";

export const buyRequestsColumns: TableColumnsType<TBuyTokens> = [
  {
    title: "Username",
    key: "username",
    dataIndex: "username",
    width: 150,
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    render: (value: number, record) =>
      `${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} ${record.coin.toUpperCase()}`,
    width: 150,
  },
  {
    title: "Amount (USDT)",
    key: "amountInUsdt",
    dataIndex: "amountInUsdt",
    render: (value: number) =>
      `${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} USDT`,
    width: 150,
  },

  {
    title: "Network Type",
    dataIndex: "networkType",
    key: "networkType",
    width: 150,
  },
  {
    title: "Blockchain Address",
    dataIndex: "blockchainAddress",
    key: "blockchainAddress",
    render: (value) => (
      <Typography.Paragraph style={{ margin: 0 }} copyable>
        {value}
      </Typography.Paragraph>
    ),
    width: 250,
  },

  {
    title: "Created At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value) => moment(value).format("lll"),
  },
];
