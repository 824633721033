import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Row } from "antd";
import { memo, useMemo, useState } from "react";
import BlockchainAccountModal from "./BlockchainAccountModal";
import { useSettingsQuery } from "../../../../hooks/settings/query/useSettings.query";
import PageContainer from "../../../../ui/page-container/PageContainer";
import AccountCard from "./AccountCard";

const Bep20FormDetails = () => {
  const { isLoading, data, refetch } = useSettingsQuery();

  const [accountModal, setAccountModal] = useState<{
    isModalOpen: boolean;
    data: TBlockchainAccount | undefined;
  }>({
    isModalOpen: false,
    data: undefined,
  });

  const settings: TSettingsModel = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [data, isLoading]);

  return (
    <Flex vertical>
      <Flex justify="flex-end">
        <Button
          onClick={() =>
            setAccountModal({ data: undefined, isModalOpen: true })
          }
          icon={<PlusOutlined />}
          type="primary"
        >
          Add Account
        </Button>
      </Flex>
      <PageContainer
        loading={isLoading}
        emptyData={settings?.paymentMethods?.bep20?.length === 0}
        emptyDataMessage="No account found"
      >
        <Row style={{ marginTop: 20 }} gutter={24}>
          {settings?.paymentMethods?.bep20.map((account) => (
            <Col xl={6} key={account._id} xs={24} lg={12} md={12} sm={12}>
              <AccountCard
                handleEditClick={() =>
                  setAccountModal({ data: account, isModalOpen: true })
                }
                data={account}
                type="bep20"
                refetch={refetch}
              />
            </Col>
          ))}
        </Row>
        {accountModal.isModalOpen && (
          <BlockchainAccountModal
            refetch={refetch}
            type="bep20"
            data={accountModal.data}
            isModalOpen={accountModal.isModalOpen}
            handleCancel={() =>
              setAccountModal({ data: undefined, isModalOpen: false })
            }
          />
        )}
      </PageContainer>
    </Flex>
  );
};

export default memo(Bep20FormDetails);
