import * as Yup from "yup";

export const socialLinksValidation = Yup.object().shape({
  socialLinks: Yup.object({
    instagram: Yup.string().url("Enter valid url"),
    telegram: Yup.string().url("Enter valid url"),
    facebook: Yup.string().url("Enter valid url"),
    youtube: Yup.string().url("Enter valid url"),
  }),
});
