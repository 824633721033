import { FC, useEffect } from "react";
import { useWebsocket } from "../services/socket.service";

import { message } from "antd";

interface INewDepositRequest {
  callback?: () => void;
}

const NewDepositRequest: FC<INewDepositRequest> = ({ callback }) => {
  const { socket } = useWebsocket();

  useEffect(() => {
    if (socket) {
      socket.on("new-deposit-request", () => {
        callback?.();
        message.info("New USDT deposit request");
      });
    }
    return () => {
      socket?.off("new-deposit-request");
    };
  }, [socket, callback]);

  return null;
};

export default NewDepositRequest;
