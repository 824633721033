import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  Button,
  Card,
  Image,
  message,
  notification,
  Popconfirm,
  Typography,
} from "antd";
import Meta from "antd/es/card/Meta";
import { FC, memo } from "react";
import { useDeleteAccountMutation } from "../../../../hooks/settings/mutation/useDeleteAccount.mutation";

interface IAccountCard {
  data: TBlockchainAccount;
  type: "bep20" | "trc20";
  refetch: () => void;
  handleEditClick: () => void;
}

const AccountCard: FC<IAccountCard> = ({
  data: { blockchainNetwork, depositAddress, qrCode, _id },
  type,
  refetch,
  handleEditClick,
}) => {
  const { isPending, mutateAsync } = useDeleteAccountMutation();

  const handleDelete = async () => {
    const res = await mutateAsync({ accountId: _id, type: type });
    if (res.status === "success") {
      refetch();
      message.success(res.message);
    } else {
      notification.error({
        message: res.data.message,
      });
    }
  };

  return (
    <Card
      style={{ marginTop: 20 }}
      hoverable
      cover={<Image src={qrCode} height={200} />}
      actions={[
        <Button type="text" onClick={handleEditClick}>
          <EditTwoTone key="edit" />
        </Button>,
        <Popconfirm
          title="Confirmation"
          description="Are you sure to delete this account?"
          onConfirm={handleDelete}
          okText="Yes"
          cancelText="No"
        >
          <Button loading={isPending} type="text">
            <DeleteTwoTone twoToneColor="red" key={"delete"} />
          </Button>
        </Popconfirm>,
      ]}
    >
      <Meta
        title={
          <Typography.Paragraph copyable>
            {blockchainNetwork}
          </Typography.Paragraph>
        }
        description={
          <Typography.Paragraph copyable>{depositAddress}</Typography.Paragraph>
        }
      />
    </Card>
  );
};

export default memo(AccountCard);
