import {
  Button,
  Form,
  Input,
  message,
  notification,
  Space,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import Trc20FormDetails from "./components/Trc20FormDetails";
import Bep20FormDetails from "./components/Bep20FormDetails";
import { useUpdateDetailsChangeTimeMutation } from "../../../hooks/settings/mutation/useUpdateDetailsChangeTime.mutation";
import { yupValidator } from "../../../utils/yupSync";
import * as Yup from "yup";
import PageContainer from "../../../ui/page-container/PageContainer";
import { useSettingsQuery } from "../../../hooks/settings/query/useSettings.query";
import { useMemo } from "react";

type IFieldType = {
  detailsChangingTime: "";
};

const validationSchema = Yup.object().shape({
  detailsChangingTime: Yup.string().required("Field is required"),
});

const PaymentMethods = () => {
  const { isPending, mutateAsync } = useUpdateDetailsChangeTimeMutation();

  const { isLoading, data, refetch } = useSettingsQuery();
  const settings: TSettingsModel = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [data, isLoading]);

  const items: TabsProps["items"] = [
    {
      key: "beb20",
      label: "BEP-20",
      children: <Bep20FormDetails />,
    },
    {
      key: "trc20",
      label: "TRC-20",
      children: <Trc20FormDetails />,
    },
  ];

  const [form] = Form.useForm<IFieldType>();
  const yupSync = yupValidator(validationSchema, form.getFieldsValue);

  const onFinish = async (values: IFieldType) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      refetch();
      message.success(res.message);
    } else {
      notification.error({
        message: res.data.message,
      });
    }
  };

  return (
    <PageContainer loading={isLoading}>
      <Typography.Title level={4}>Payment Methods</Typography.Title>
      <Typography.Title level={4}>USDT Deposit</Typography.Title>

      <Form
        style={{ marginTop: 20 }}
        layout="vertical"
        name="blockchain account"
        size="large"
        form={form}
        onFinish={onFinish}
        initialValues={{
          detailsChangingTime:
            settings?.paymentMethods?.detailsChangingTime ?? 10,
        }}
      >
        <Space align="end" wrap>
          <Form.Item<IFieldType>
            name="detailsChangingTime"
            rules={[yupSync]}
            label="Details Changing interval (in Minutes)"
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isPending}
              block
              size="middle"
              type="primary"
              htmlType="submit"
            >
              Update
            </Button>
          </Form.Item>
        </Space>
      </Form>

      <Tabs defaultActiveKey="1" items={items} />
    </PageContainer>
  );
};

export default PaymentMethods;
