import { Flex, Typography } from "antd";

const Dashboard = () => {
  return (
    <Flex>
      <Typography.Title level={4}>Dashboard Access</Typography.Title>
    </Flex>
  );
};

export default Dashboard;
