import { useMutation } from "@tanstack/react-query";
import { apiUrls } from "../../apiUrls";
import { request } from "../../../services/axios.service";

const update = async (data: { socialLinks: TSettingsModel["socialLinks"] }) => {
  const response: TServerResponse = await request({
    url: apiUrls.settings.UPDATE_SOCIAL_URLS,
    method: "POST",
    data,
  });
  return response;
};

export const useUpdateSocialUrlsMutation = () => {
  return useMutation({ mutationFn: update });
};
