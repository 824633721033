export const getStatusTagColors = (status: TDepositRequest["status"]) => {
  switch (status) {
    case "new":
      return "#228be6";
    case "approved":
      return "#40c057";
    case "rejected":
      return "#fa5252";

    default:
      return "#228be6";
  }
};
