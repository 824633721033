import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";
const fetch = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.settings.FETCH_SETTINGS,
    method: "GET",
  });

  return response;
};

export const useSettingsQuery = () => {
  return useQuery({
    queryKey: [apiUrls.settings.FETCH_SETTINGS],
    queryFn: fetch,
  });
};
