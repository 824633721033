import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Segmented,
  Typography,
} from "antd";
import React, { FC, memo, useEffect, useState } from "react";
import { forms } from "../../../forms";

import { useUpdateUserWalletMutation } from "../../../hooks/users/mutation/useUpdateUserWallet.mutation";

interface IWalletModal {
  close: () => void;
  opened: boolean;
  user?: TUser;
  refetch: () => void;
}
type IFieldType = typeof forms.wallet.values;

const WalletModal: FC<IWalletModal> = ({ close, opened, user, refetch }) => {
  const { mutateAsync, isPending } = useUpdateUserWalletMutation();

  const [action, setAction] = useState("credit");

  const [form] = Form.useForm<IFieldType>();

  const onFinish = async (values: IFieldType) => {
    const res = await mutateAsync({
      action,
      amount: Number(values.amount),
      userId: user?._id ?? "",
    });

    if (res.status === "success") {
      refetch();
      message.success(res.message);
      close();
    } else {
      message.error(res.data.message);
    }
  };

  useEffect(() => {
    if (opened) {
      form.resetFields();
    }
  }, [opened, form]);

  return (
    <Modal title="Update Wallet" open={opened} onCancel={close} footer={false}>
      <Flex
        style={{ margin: "20px 0px" }}
        justify="space-between"
        align="center"
      >
        <Typography.Text style={{ fontSize: 12 }}>
          User: <b>{user?.username}</b>
        </Typography.Text>
        <Typography.Text style={{ fontSize: 12 }}>
          Balance:{" "}
          <b>
            {user?.usdtWallet.deposit.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            USDT
          </b>
        </Typography.Text>
      </Flex>

      <Form
        style={{ marginTop: 20 }}
        layout="vertical"
        name="basic"
        size="large"
        form={form}
        onFinish={onFinish}
        initialValues={forms.wallet.values}
      >
        <Segmented
          onChange={setAction}
          style={{ margin: "20px 0px" }}
          options={[
            { label: "Credit", value: "credit" },
            { label: "Debit", value: "debit" },
          ]}
          value={action}
          block
        />

        <Form.Item<IFieldType>
          name="amount"
          rules={[
            {
              required: true,
              message: "Amount field is required",
              max: user?.usdtWallet.deposit ?? 0,
            },
            {
              pattern: /^[0-9]*$/,
              message: "Only digits are allowed",
            },
            {
              validator: (_, value) => {
                const maxAmount = user?.usdtWallet.deposit ?? 0;
                if (
                  action === "debit" &&
                  value &&
                  parseFloat(value) > maxAmount
                ) {
                  return Promise.reject(
                    new Error(`Amount cannot exceed ${maxAmount} USDT`)
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Amount" />
        </Form.Item>

        <Form.Item>
          <Button
            loading={isPending}
            block
            size="large"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(WalletModal);
