export const styles: Record<string, React.CSSProperties> = {
  menuStyle: {
    cursor: "pointer",
    minWidth: 120,
    textAlign: "center",
    borderRadius: 50,
    padding: "3px 0px",
    textTransform: "capitalize",
  },
};
