import { useMemo, useState } from "react";
import PageContainer from "../../ui/page-container/PageContainer";
import { Flex, Segmented, Table, Typography } from "antd";

import { columns } from "../../columns";
import StatusMenu from "./components/status-menu/StatusMenu";
import { useBuyTokenRequestsQuery } from "../../hooks/buy-token-requests/query/useBuyTokenRequests.query";
import NewBuyRequest from "../../socket-events/NewBuyRequest";

const BuyTokenRequests = () => {
  const [status, setStatus] = useState("All");

  const { data, isLoading, refetch } = useBuyTokenRequestsQuery({
    status: status.toLowerCase(),
  });

  const tableColumns = [...columns.BUY_REQUESTS];

  tableColumns.push({
    key: "status",
    dataIndex: "status",
    title: "Status",
    render: (_value, record) => <StatusMenu data={record} refetch={refetch} />,
    width: 200,
  });

  const dataSource: TBuyTokens[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data ?? [];
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <PageContainer loading={isLoading}>
      <Flex align="center" justify="space-between" wrap>
        <Typography.Title level={4}>Buy Token Requests</Typography.Title>

        <Segmented
          size="middle"
          options={["All", "New", "Approved", "Rejected"]}
          value={status}
          onChange={setStatus}
        />
      </Flex>
      <Table
        scroll={{ x: "max-content" }}
        style={{ marginTop: 20 }}
        size="small"
        dataSource={dataSource}
        columns={tableColumns}
        pagination={{ hideOnSinglePage: true }}
      />
      <NewBuyRequest callback={() => refetch()} />
    </PageContainer>
  );
};

export default BuyTokenRequests;
