import {
  Dropdown,
  Flex,
  message,
  Modal,
  notification,
  Tag,
  Typography,
} from "antd";
import { FC, memo } from "react";
import { styles } from "./styles";
import { getStatusTagColors } from "../../../../utils/getStatusTagColors";
import { useUpdateBuyRequestStatusMutation } from "../../../../hooks/buy-token-requests/mutation/useUpdateBuyRequestStatus.mutation";

interface IStatusMenu {
  data: TBuyTokens;
  refetch: () => void;
}

const StatusMenu: FC<IStatusMenu> = ({ data: { status, _id }, refetch }) => {
  const { isPending, mutateAsync } = useUpdateBuyRequestStatusMutation();

  const handleStatusChange = async (value: string) => {
    const res = await mutateAsync({ _id, status: value });
    if (res.status === "success") {
      refetch();
      message.success(res.message);
    } else {
      notification.error({ message: res.data?.message });
    }
  };

  return (
    <Dropdown
      disabled={status !== "new" || isPending}
      menu={{
        onClick: (info) => {
          Modal.confirm({
            title: "Confirmation !",
            centered: true,
            onOk: () => handleStatusChange(info.key),

            content: (
              <Flex>
                Are you sure you want to
                {info.key === "rejected" ? (
                  <Typography.Text
                    style={{ margin: "0px 5px" }}
                    type="danger"
                    strong
                  >
                    Reject
                  </Typography.Text>
                ) : (
                  <Typography.Text
                    style={{ margin: "0px 5px" }}
                    type="success"
                    strong
                  >
                    Approve
                  </Typography.Text>
                )}
                this request
              </Flex>
            ),
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            ),
          });
        },
        items: [
          {
            label: "New",
            key: "new",
            disabled: true,
          },
          {
            label: "Approve",
            key: "approved",
          },
          {
            label: "Reject",
            key: "rejected",
          },
        ],
      }}
      trigger={["click"]}
    >
      <Tag style={styles.menuStyle} color={getStatusTagColors(status)}>
        {status}
      </Tag>
    </Dropdown>
  );
};

export default memo(StatusMenu);
