import { DashboardOutlined, SettingFilled } from "@ant-design/icons";
import {
  IconArrowAutofitDown,
  IconArrowAutofitUp,
  IconUsers,
} from "@tabler/icons-react";
import { MenuProps } from "antd";

import React from "react";
import { NavigateFunction } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

const getItem = (data: {
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  navigate: NavigateFunction;
  subMenu?: boolean;
}): MenuItem => {
  return {
    ...data,
    onClick: () => !data.subMenu && data.navigate(data.key as string),
  } as MenuItem;
};

export const navLinks = (navigate: NavigateFunction) => {
  return [
    getItem({
      label: "Dashboard",
      key: "/",
      icon: <DashboardOutlined />,
      navigate,
    }),
    getItem({
      label: "Users",
      key: "/users",
      icon: <IconUsers size={16} />,
      navigate,
    }),
    getItem({
      label: "Deposit Requests",
      key: "/deposit-requests",
      icon: <IconArrowAutofitDown size={16} />,
      navigate,
    }),
    getItem({
      label: "Buy Token Requests",
      key: "/buy-token-requests",
      icon: <IconArrowAutofitUp size={16} />,
      navigate,
    }),
    getItem({
      label: "Settings",
      key: "/settings",
      icon: <SettingFilled />,
      subMenu: true,
      navigate,
      children: [
        getItem({
          label: "App Information",
          key: "/settings/app-information",
          navigate,
        }),
        getItem({
          label: "Payment Methods",
          key: "/settings/payment-methods",
          navigate,
        }),
        getItem({
          label: "App Constants",
          key: "/settings/app-constants",
          navigate,
        }),
        getItem({
          label: "Social Links",
          key: "/settings/social-links",
          navigate,
        }),
      ],
    }),
  ];
};
