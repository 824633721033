import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";

const update = async (data: {
  userId: string;
  action: string;
  amount: number;
}) => {
  const response: TServerResponse = await request({
    url: apiUrls.user.UPDATE_USER_WALLET,
    method: "POST",
    data,
  });
  return response;
};

export const useUpdateUserWalletMutation = () => {
  return useMutation({ mutationFn: update });
};
