import { useMemo, useState } from "react";
import PageContainer from "../../ui/page-container/PageContainer";
import { Flex, Segmented, Table, Typography } from "antd";
import { useDepositRequestsQuery } from "../../hooks/deposit-requests/query/useDepositRequests.query";
import { columns } from "../../columns";
import StatusMenu from "./components/status-menu/StatusMenu";

const DepositRequests = () => {
  const [status, setStatus] = useState("All");

  const { data, isLoading, refetch } = useDepositRequestsQuery({
    status: status.toLowerCase(),
  });

  const tableColumns = [...columns.DEPOSIT_REQUESTS];

  tableColumns.push({
    key: "status",
    dataIndex: "status",
    title: "Status",
    render: (_value, record) => <StatusMenu data={record} refetch={refetch} />,
  });

  const dataSource: TDepositRequest[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data ?? [];
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <PageContainer loading={isLoading}>
      <Flex align="center" justify="space-between" wrap>
        <Typography.Title level={4}>Deposit Requests</Typography.Title>

        <Segmented
          size="middle"
          options={["All", "New", "Approved", "Rejected"]}
          value={status}
          onChange={setStatus}
        />
      </Flex>
      <Table
        scroll={{ x: "max-content" }}
        style={{ marginTop: 20 }}
        size="small"
        dataSource={dataSource}
        columns={tableColumns}
        pagination={{ hideOnSinglePage: true }}
      />
    </PageContainer>
  );
};

export default DepositRequests;
