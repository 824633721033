import classes from "./LoginPage.module.css";
import { Button, Flex, Form, Input, notification } from "antd";
import { Typography } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { yupValidator } from "../../utils/yupSync";
import { forms } from "../../forms";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { useLoginMutation } from "../../hooks/auth/useLoginMutation";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type IFieldType = typeof forms.login.values;
const LoginPage = () => {
  const { Title } = Typography;
  const [form] = Form.useForm<IFieldType>();
  const yupSync = yupValidator(forms.login.validation, form.getFieldsValue);
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const { mutateAsync, isPending } = useLoginMutation();

  const onFinish = useCallback(
    async (values: IFieldType) => {
      const res = await mutateAsync(values);
      if (
        res.status === "success" &&
        signIn({
          token: res.data.token,
          expiresIn: res.data.expiresIn,
          tokenType: "Bearer",
          authState: res.data.authUserState,
        })
      ) {
        notification.success({
          message: res.message,
        });
        sessionStorage.setItem("token", res.data.token);
        form.resetFields();
        navigate("/");
      } else {
        notification.error({
          message: res.data.message,
        });
      }
    },
    [mutateAsync, signIn, navigate, form]
  );

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Flex className={classes.root} justify="center" align="center">
      <Flex className={classes.formContainer} vertical>
        <Title type="secondary" style={{ margin: 0 }} level={5}>
          Welcome to,
        </Title>
        <Title style={{ marginTop: 5 }} level={3}>
          {process.env.REACT_APP_PROJECT_NAME} Admin
        </Title>

        <Form
          style={{ marginTop: 20 }}
          layout="vertical"
          name="basic"
          size="large"
          form={form}
          onFinish={onFinish}
          initialValues={forms.login.values}
        >
          <Form.Item<IFieldType> name="username" rules={[yupSync]}>
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>

          <Form.Item<IFieldType> name="password" rules={[yupSync]}>
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isPending}
              block
              size="large"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
