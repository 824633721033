import { appConstantsValidation } from "./app-constant/validation";
import { appConstantsIniValues } from "./app-constant/values";
import { appInfoValidation } from "./app-info/validation";
import { appInfoIniValues } from "./app-info/values";
import { blockchainAccountValidation } from "./blockchain-account/validation";
import { blockchainAccountIniValues } from "./blockchain-account/values";
import { loginValidation } from "./login/validation";
import { loginIniValues } from "./login/values";
import { socialLinksValidation } from "./social-links/validation";
import { socialLinksIniValues } from "./social-links/values";
import { walletValidation } from "./wallet/validation";
import { walletIniValues } from "./wallet/values";

export const forms = {
  login: {
    values: loginIniValues,
    validation: loginValidation,
  },
  blockchainAccount: {
    values: blockchainAccountIniValues,
    validation: blockchainAccountValidation,
  },
  appConstants: {
    values: appConstantsIniValues,
    validation: appConstantsValidation,
  },
  socialLinks: {
    values: socialLinksIniValues,
    validation: socialLinksValidation,
  },
  appInformation: {
    values: appInfoIniValues,
    validation: appInfoValidation,
  },
  wallet: {
    validation: walletValidation,
    values: walletIniValues,
  },
};
