import { Image, TableColumnsType } from "antd";
import moment from "moment";

export const depositRequestsColumns: TableColumnsType<TDepositRequest> = [
  {
    title: "Username",
    key: "username",
    dataIndex: "username",
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    render: (value: number) =>
      `${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} USDT`,
  },
  {
    title: "Payment Receipt",
    key: "receipt",
    dataIndex: "receipt",
    render: (value) => <Image width={50} height={50} src={value} />,
  },
  {
    title: "Created At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value) => moment(value).format("lll"),
  },
];
