import { authApis } from "./auth.api";
import { buyTokenRequestsApi } from "./buy-token-requests.api";
import { depositRequestsApi } from "./deposit-requests.api";
import { settingsApi } from "./settings.api";
import { userApi } from "./user.api";

export const apiUrls = {
  auth: authApis,
  settings: settingsApi,
  depositRequests: depositRequestsApi,
  buyTokenRequest: buyTokenRequestsApi,
  user: userApi,
};
