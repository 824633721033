import { forms } from "../../../forms";
import PageContainer from "../../../ui/page-container/PageContainer";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { yupValidator } from "../../../utils/yupSync";
import { useSettingsQuery } from "../../../hooks/settings/query/useSettings.query";
import { useMemo } from "react";
import { useUpdateSocialUrlsMutation } from "../../../hooks/settings/mutation/useUpdateSocialUrls.mutation";

interface IFieldType {
  socialLinks: TSettingsModel["socialLinks"];
}

const SocialLinks = () => {
  const [form] = Form.useForm<IFieldType>();
  const yupSync = yupValidator(
    forms.socialLinks.validation,
    form.getFieldsValue
  );

  const { isLoading, data } = useSettingsQuery();

  const settings: TSettingsModel = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);

  const { isPending, mutateAsync } = useUpdateSocialUrlsMutation();

  const onFinish = async (values: IFieldType) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      message.success(res.message);
    } else {
      message.error(res.data.message);
    }
  };

  return (
    <PageContainer loading={isLoading}>
      <Typography.Title level={4}>Social Links</Typography.Title>
      <Form
        style={{ marginTop: 20 }}
        layout="vertical"
        name="blockchain account"
        size="middle"
        form={form}
        onFinish={onFinish}
        initialValues={{
          ...forms.socialLinks.values,
          socialLinks: settings?.socialLinks,
        }}
      >
        <Row gutter={10}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["socialLinks", "facebook"]}
              rules={[yupSync]}
              label="Facebook"
            >
              <Input placeholder="Enter facebook url" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["socialLinks", "instagram"]}
              rules={[yupSync]}
              label="Instagram"
            >
              <Input placeholder="Enter instagram url" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["socialLinks", "telegram"]}
              rules={[yupSync]}
              label="Telegram Support"
            >
              <Input placeholder="Enter support url" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["socialLinks", "youtube"]}
              rules={[yupSync]}
              label="Youtube"
            >
              <Input placeholder="Enter youtube url" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            loading={isPending}
            size="large"
            type="primary"
            htmlType="submit"
          >
            Update Social urls
          </Button>
        </Form.Item>
      </Form>
    </PageContainer>
  );
};

export default SocialLinks;
