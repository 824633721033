import { useQuery } from "@tanstack/react-query";
import { request } from "../../services/axios.service";
import { apiUrls } from "../apiUrls";

const getProfile = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.auth.GET_PROFILE,
    method: "GET",
  });

  return response;
};

export const useCheckAuthenticated = () => {
  return useQuery({
    queryKey: ["admin", "get-profile"],
    queryFn: getProfile,
    enabled: false,
  });
};
